/**
 * Ajax load the spritesheet and inject into top of <body>
 * theme.path should be set in base.twig & themepath.js.php (Gutenberg)
 */
fetch(theme.path + '/img/svg-sprite.svg')
	.then((response) => response.text())
	.then((data) => {
		// inject into dom
		const svgDiv = document.createElement('div');
		svgDiv.setAttribute('id', 'svg-spritesheet');
		svgDiv.setAttribute('aria-hidden', 'true');
		svgDiv.style.cssText = 'position: absolute; width: 0; height: 0; overflow: hidden';
		svgDiv.innerHTML = data;
		document.body.prepend(svgDiv);
		// console log it for development
		if (theme.debug) {
			const outputDom = document.getElementById('outputsvg')
			// console.log('Copy and paste: ')
			const elements = document.querySelectorAll('#svg-spritesheet symbol');
			Array.prototype.forEach.call(elements, function (el,i) {
				const svg = `<svg class='max-w-full' width='${el.viewBox.baseVal.width}' height='${el.viewBox.baseVal.height}' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg'><use xlink:href='#${el.id}'/></svg>`
				// console.log(svg)
				if (outputDom){
					outputDom.innerHTML += `<div class="border border-red-100 border-opacity-50 p-4"><code><textarea onClick="this.select();" rows="5" class="bg-transparent text-current text-xs block w-full mb-4 border-0 p-0">${svg}</textarea></code>${svg}</div>`
				}
			})
		}
	})
	.catch(error=>{
		console.error('Sprites error: ', error)
	});