import Splide from '@splidejs/splide';

// Hero Sliders
const initHeroSliders = () => {    
  const heroSliders = document.querySelectorAll('.hero-slider');
  if(heroSliders.length) {
    heroSliders.forEach((item) => {
      new Splide(item, {
        type: 'loop',
        perPage: 1,
        perMove: 1,        
        arrows: false,  
        autoplay: true,        
        pauseOnHover: false 
      }).mount();    
    })    
  }
}
window.addEventListener('load', () => { initHeroSliders() })



// Hero Sliders
const initFeaturedCarousels = () => {    
  const featuredCarousels = document.querySelectorAll('.featured-carousel-slider');
  if(featuredCarousels.length) {
    featuredCarousels.forEach((item) => {
      new Splide(item, {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        gap: '1rem',
        pagination: true,
        mediaQuery: 'min',
        breakpoints: {
          768: {
            perPage: 2,
            perMove: 2,
            gap: '1.5rem',
          },
          1024: {
            pagination: false,
            perMove: 1
          }
        }
      }).mount();    
    })    
  }
}
window.addEventListener('load', () => { initFeaturedCarousels() })



// Image Slideshows
const initImageSlideshows = () => {    
  const imageSlideshows = document.querySelectorAll('.image-slideshow-slider');
  if(imageSlideshows.length) {
    imageSlideshows.forEach((item) => {
      new Splide(item, {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        arrows: false,
        pagination: true
      }).mount();    
    })    
  }
}
window.addEventListener('load', () => { initImageSlideshows() })


// Testimonial Slideshows
const initTestimonialSliders = () => {    
  const testimonialSliders = document.querySelectorAll('.testimonials-slider');
  if(testimonialSliders.length) {
    testimonialSliders.forEach((item) => {
      new Splide(item, {        
        type: 'loop',
        perPage: 1,
        perMove: 1,
        arrows: false,
        pagination: true
      }).mount();    
    })    
  }
}
window.addEventListener('load', () => { initTestimonialSliders() })



// Testimonial Slideshows
const initNfiConnectCarousels = () => {    
  const nfiConnectCarousels = document.querySelectorAll('.nfi-connect-carousel');
  if(nfiConnectCarousels.length) {
    nfiConnectCarousels.forEach((item) => {
      new Splide(item, {        
        type: 'loop',
        perPage: 1,
        perMove: 1,
        arrows: false,
        pagination: true,
        mediaQuery: 'min',
        breakpoints: {
          768: {
            perPage: 3,
            pagination: false,
            drag: false,            
          },
        }
      }).mount();    
    })    
  }
}
window.addEventListener('load', () => { initNfiConnectCarousels() })



// Timeline Slideshows
const initTimelineSliders = () => {      
  
  const timelineSliders = document.querySelectorAll('.timeline-slider');
  const timelineButtons = document.querySelectorAll('.timeline-btn');
  const timelineScroller = document.querySelector('.timeline-controls-scroller');
  const timelineCurrent = document.querySelector('.timeline-index-current');
  const timelineArrowPrev = document.querySelector('.timeline-arrow--prev');
  const timelineArrowNext = document.querySelector('.timeline-arrow--next');

  if(timelineSliders.length) {
    timelineSliders.forEach((item) => {

      let splide = null;

      splide = new Splide(item, {
        type: 'fade',
        perPage: 1,
        perMove: 1,
        arrows: false,
        pagination: false,
        mediaQuery: 'min',
        drag: false
      });
      splide.mount();

      timelineButtons.forEach((item) => {
        item.addEventListener('click', (e) => {
          const slideIndex = Number(e.currentTarget.getAttribute('data-index') - 1);
          splide.go(slideIndex);          
          
          // timelineCurrent.innerHTML = Number(splide.index + 1);

          Array.from(item.parentElement.children).forEach((sibling) => {
            sibling.classList.remove('timeline-btn--active');
          });          
          item.classList.add('timeline-btn--active');

        })
      })

      timelineScroller.addEventListener('scroll', (e) => {
        
        if (e.currentTarget.scrollLeft > 0) {
          e.currentTarget.parentElement.classList.add('scrolled-left')
        } else {
          e.currentTarget.parentElement.classList.remove('scrolled-left')
        }

        if (e.currentTarget.scrollLeft >= (e.currentTarget.scrollWidth - e.currentTarget.clientWidth)) {
          e.currentTarget.parentElement.classList.add('scroll-end')
        } else {
          e.currentTarget.parentElement.classList.remove('scroll-end')
        }
        
      })

      timelineArrowPrev.addEventListener('click', (e) => {
        timelineScroller.scrollBy({ left: -68, top: 0, behavior: 'smooth' })
        splide.go(Number(splide.index - 1))
        timelineCurrent.innerHTML = Number(splide.index + 1);
        timelineButtons.forEach((item) => {
          if (item.getAttribute('data-index') == Number(splide.index + 1)) {
            item.classList.add('timeline-btn--active')
          } else {
            item.classList.remove('timeline-btn--active')
          }
        })
      })
      timelineArrowNext.addEventListener('click', (e) => {
        timelineScroller.scrollBy({ left: 68, top: 0, behavior: 'smooth' })
        splide.go(Number(splide.index + 1))
        timelineCurrent.innerHTML = Number(splide.index + 1);
        timelineButtons.forEach((item) => {
          if (item.getAttribute('data-index') == Number(splide.index + 1)) {
            item.classList.add('timeline-btn--active')
          } else {
            item.classList.remove('timeline-btn--active')
          }
        })
      })

    })
  }
}
window.addEventListener('load', () => { initTimelineSliders() })



// Facility Galleries
// const initFacilityGalleries = () => {    
//   const facilitySlideshows = document.querySelectorAll('.facility-gallery-slider');
//   if(facilitySlideshows.length) {
//     facilitySlideshows.forEach((item) => {
//       new Splide(item, {
//         type: 'loop',
//         perPage: 1,
//         perMove: 1,
//         arrows: false,
//         pagination: true
//       }).mount();    
//     })    
//   }
// }
// window.addEventListener('load', () => { initFacilityGalleries() })