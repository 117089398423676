const { createApp } = Vue

import Splide from '@splidejs/splide';

const app = createApp({  
  data: () => ({
    postsCount: [],
    posts: [],
    regions: [],
    dropdownOpen: false,
    activeFilter: 'All'
  }),
  methods: {
    async totalPosts() {
      let response = await fetch('/graphql',  {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        query: `
          {
            facilities(first:99) {
              edges {
                cursor      
              }
            }
          }
        `
        })
      });
      response = await response.json();
      this.postsCount = response.data.facilities.edges ? response.data.facilities.edges.length : null;
    },
    async filteredData(slug, name) {
      let response = await fetch('/graphql',  {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        query: `
          query($slug: String!) {
            facilities(
              first: 99,
              where: {
                taxQuery: {
                  relation: OR,
                  taxArray: [
                    {
                      terms: [$slug],
                      taxonomy: FACILITYREGION,
                      operator: IN,
                      field: SLUG
                    }
                  ]
                }
              }
            ) {
              edges {
                node {
                  title
                  facilityCustomFields {
                    address
                    list {
                      item
                    }
                    googleMapLink
                    gallery {
                      sourceUrl(size: ASPECT_5_3)
                    }
                  }
                  facilityCountries(first: 1) {
                    nodes {
                      name
                      taxonomyFacilityCountry {
                        flag {
                          sourceUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          slug: slug
        },
        })
      });
      response = await response.json();
      this.posts = response.data.facilities;
      this.activeFilter = name
    },
    async getPosts() {
      let response = await fetch('/graphql',  {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        query: `
          {
            facilities(first: 99) {
              edges {
                node {
                  title
                  facilityCustomFields {
                    address
                    list {
                      item
                    }
                    googleMapLink
                    gallery {
                      sourceUrl(size: ASPECT_5_3)
                    }
                  }
                  facilityCountries(first: 1) {
                    nodes {
                      name
                      taxonomyFacilityCountry {
                        flag {
                          sourceUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `
        })
      });
      response = await response.json();
      this.posts = response.data.facilities;
      this.activeFilter = 'All'
    },
    async getRegions() {
      let response = await fetch('/graphql',  {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        query: `
          {
            facilityRegions {
              edges {
                node {
                  databaseId
                  slug
                  name
                  count
                }
              }
            }
          }
        `
        })
      });
      response = await response.json();
      this.regions = response.data.facilityRegions;
    },
    dropdownToggle() {
      this.dropdownOpen = !this.dropdownOpen
    }
  },
  mounted() {
    this.totalPosts()
    this.getPosts()
    this.getRegions()
  }
})

app.component('facility-item', {
  props: ['post', 'index'],
  data: () => ({
    open: false,
    facilityCarousel: null
  }),
  computed: {
    countryFlagUrl() {
      return this.$props.post.node.facilityCountries.nodes[0].taxonomyFacilityCountry.flag.sourceUrl;
    },
    countryName() {
      return this.$props.post.node.facilityCountries.nodes[0].name;
    }
  },
  methods: {
    initCarousel() {
      if( !this.facilityCarousel ) {
        this.facilityCarousel = new Splide(this.$refs.splide, {          
          pagination: true,
          arrows: false
        })
        this.facilityCarousel.mount()
      }
    }
  },
  updated() {    
    if( this.$refs.splide ) this.initCarousel()
  },
  mounted() {    
    if( this.$refs.splide ) this.initCarousel()
  },
  template: `    
    <div>

      <div class="accordion-header">
        <button @click="open = ! open" :aria-controls="'facility-panel-' + index" aria-expanded="true" class="accordion-trigger [ block ][ py-6 ][ w-full ][ flex justify-between items-center ]" :id="'facility-button-' + index"> 
          <div class="[ flex gap-x-4 ]">
            <img :src="countryFlagUrl" width="24" height="16" :alt="countryName + ' flag'" />
            <span class="[ font-medium text-lg lg:text-xl ]" v-if="post.node.title">{{post.node.title}}</span>
          </div>          
          <div class="[ flex gap-x-4 lg:gap-x-32 ]">
            <span class="[ uppercase text-xs tracking-wide text-right font-medium ][ pl-4 ]">{{ countryName }}</span>
            <svg class="[ h-[15px] w-[15px] lg:h-[22px] lg:w-[22px] ][ transition ]" :class="open ? 'rotate-45' : ''" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <use xlink:href="#svg-plus"/>
            </svg>
          </div>
        </button>
      </div>

      <div :aria-labelledby="'facility-button-' + index" class="accordion-panel [ pb-6 ]" :aria-hidden="! open ? 'true' : 'false'" :id="'facility-panel-' + index">
        <div class="[ lg:flex lg:items-start lg:gap-24 ]">
          <div class="[ w-full lg:w-2/5 ]">
            <div class="[ flex gap-x-6 ]">
              <svg height="12" width="12" class="[ mt-2 ]">
                <use xlink:href="#svg-location"/>
              </svg>
              <p v-if="post.node.facilityCustomFields.address" v-html="post.node.facilityCustomFields.address" class="[ mt-0 ][ font-medium leading-snug ]"></p>
            </div>
            <a v-if="post.node.facilityCustomFields.google_map_link" :href="post.node.facilityCustomFields.google_map_link" target="_blank" rel="noopener">View on Google Maps</a>
            <ul class="adl-list">
              <li v-for="li in post.node.facilityCustomFields.list" class="[ py-3 border-b ]">{{li.item}}</li>
            </ul>
          </div>
          <div class="facility-gallery-slider splide [ w-full lg:w-3/5 ][ mb-8 ]" ref="splide">
            <div class="splide__track">
              <ul class="splide__list">
                <li v-for="image in post.node.facilityCustomFields.gallery" class="splide__slide">
                  <div class="[ aspect-[5/3] ][ relative overflow-hidden ]">
                    <img :src="image.sourceUrl" class="[ absolute top-0 left-0 ][ h-full w-full ][ object-cover object-center ]" />
                  </div>
                </li>
              </ul>
            </div>
          </div>        
        </div>
      </div>

    </div>

  `
})

app.mount('#facility-archive')