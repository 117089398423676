const { createApp } = Vue

const app = createApp({  
  data: () => ({
    posts: []    
  }),
  methods: {    
    async getPosts() {
      let response = await fetch('/graphql',  {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        query: `
          {
            teamMembers(first:99) {
              edges {
                node {
                  title
                  featuredImage {
                    node {
                      sourceUrl(size: ASPECT_5_3)
                      altText
                      title
                    }
                  }
                  teamCustomFields {          
                    bio
                    linkedin
                    position
                    shortBio
                  }
                }
              }
            }
          }
        `
        })
      });
      response = await response.json();
      this.posts = response.data.teamMembers;
    },    
  },
  mounted() {
    this.getPosts()
  }
})

app.component('team-item', {
  props: ['post', 'index'],
  data: () => ({
    overlayOpen: false
  }),
  methods: {
    initOverlay() {
      this.overlayOpen = true
      document.documentElement.classList.add('no-scroll')
    },
    closeOverlay() {
      this.overlayOpen = false
      document.documentElement.classList.remove('no-scroll')
    },
    truncateText(str) {
      let strippedString = str.replace(/(<([^>]+)>)/gi, "")
      return strippedString.slice(0, 136) + '...'
    }
  },  
  template: `    
    <div class="team-item [ flex flex-row-reverse items-center gap-x-8 sm:flex-col ][ py-8 lg:py-0 pl-6 sm:pl-0 ][ border-b sm:border-none ]">      
      <a @click.prevent="initOverlay()"  v-if="post.node.featuredImage" href="#" class="[ w-1/2 sm:w-full ][ relative overflow-hidden ][ aspect-[1/1] sm:aspect-[5/3] ]">
        <img :src="post.node.featuredImage.node.sourceUrl" :alt="post.node.featuredImage.node.altText ? post.node.featuredImage.node.altText : post.node.featuredImage.node.title" loading="lazy" class="[ absolute top-0 left-0 ][ w-full h-full ][ object-cover object-center ]" />
      </a>      
      <div class="[ lg:mb-6 ][ w-1/2 sm:w-full ]">
        <h2 v-if="post.node.title" class="[ text-xl ][ mt-0 sm:mt-6 mb-2 ]">
          <a @click.prevent="initOverlay()" href="#" class="[ no-underline ]">{{ post.node.title }}</a>
        </h2>     
        <p v-if="post.node.teamCustomFields.position" class="[ font-medium ][ mt-2 ]" v-html="post.node.teamCustomFields.position"></p>
        <p class="bio-excerpt" v-if="post.node.teamCustomFields.shortBio" v-html="post.node.teamCustomFields.shortBio"></p>
        <p class="bio-excerpt" v-else>{{ truncateText(post.node.teamCustomFields.bio) }}</p>
        <button @click="initOverlay()" class="[ inline-flex ][ bg-white border-b-2 border-black lg:border lg:border-adl-gray-100 ][ font-medium ][ py-1 lg:py-3 lg:px-5 ]">Read bio</button>
      </div>
      <div @click="closeOverlay" class="[ fixed top-0 left-0 z-[10001] ][ h-screen w-screen ][ bg-adl-overlay/50 ][ lg:transition ]" :class="overlayOpen ? '[ opacity-100 visible ]' : '[ opacity-0 invisible ]'">
        <div @click.stop class="[ w-full md:w-[806px] h-screen ][ flex flex-col ][ shadow-xl ][ lg:transition lg:duration-300 lg:delay-150 ]" :class="overlayOpen ? '[ translate-x-0 ]' : '[ translate-x-[-100%] ]'">
          <div class="[ bg-adl-blue-200 lg:bg-white ][ px-6 pt-3 pb-0 lg:py-8 lg:px-12 ]">
            <button @click="closeOverlay" class="[ flex items-center gap-2 ][ ml-auto ]">
              <span class="[ text-sm font-medium ]">Close</span>
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="13.6118" y="6.10352e-05" width="1.5" height="19.249" transform="rotate(45 13.6118 6.10352e-05)" fill="black"/>
                <rect x="15" y="13.6111" width="1.5" height="19.249" transform="rotate(135 15 13.6111)" fill="black"/>
              </svg>
            </button>
          </div>
          <div @click.stop class="[ bg-white ][ p-6 lg:p-12 ][ h-full ][ overflow-hidden ][ relative before:content-[''] before:block before:absolute before:top-0 before:left-0 before:w-full before:h-[240px] before:bg-gradient-to-b before:from-adl-blue-200 before:to-white ]">
            <div class="[ relative z-10 ][ max-w-[526px] mx-auto md:px-4 ][ overflow-y-auto ][ h-full ]">
              <img :src="post.node.featuredImage.node.sourceUrl" :alt="post.node.featuredImage.node.altText ? post.node.featuredImage.node.altText : post.node.featuredImage.node.title" loading="lazy" />
              <div class="[ flex justify-between ][ mt-6 ]">
                <div>
                  <h2 v-if="post.node.title" class="[ text-[20px] lg:text-xl ][ mt-0 mb-2 ]">{{ post.node.title }}</h2>
                  <p v-if="post.node.teamCustomFields.position" class="[ text-sm lg:text-base font-medium ][ mt-2 ]" v-html="post.node.teamCustomFields.position"></p>
                </div>
                <div v-if="post.node.teamCustomFields.linkedin">
                  <a :href="post.node.teamCustomFields.linkedin" rel="noopener" target="_blank">
                    <span class="[ sr-only ]">LinkedIn</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M14.82,0H1.18A1.18,1.18,0,0,0,0,1.18V14.82A1.18,1.18,0,0,0,1.18,16H14.82A1.18,1.18,0,0,0,16,14.82V1.18A1.18,1.18,0,0,0,14.82,0ZM2.36,13.63H4.77V6H2.36Zm1.21-8.7A1.36,1.36,0,0,1,2.18,3.55,1.36,1.36,0,0,1,3.57,2.17,1.36,1.36,0,0,1,5,3.55,1.36,1.36,0,0,1,3.57,4.93Zm10.07,8.71H11.23V9.46c0-1.23-.52-1.61-1.2-1.61A1.46,1.46,0,0,0,8.62,9.49v4.15H6.21V6H8.53V7.05h0a2.61,2.61,0,0,1,2.28-1.27c1.35,0,2.8.8,2.8,3.13Z" fill="black" style="fill-rule:evenodd"/></svg>
                  </a>
                </div>
              </div>
              <div>
                <div class="bio" v-html="post.node.teamCustomFields.bio" class="[ mb-8 ]"></div>
                <button @click="closeOverlay" class="[ btn btn--outline ][ !py-3 !px-6 ]">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  `
})


app.mount('#team-archive')