const { createApp } = Vue

const app = createApp({  
  data: () => ({
    activeFilter: 'All',
    activeFilterCount: 0,
    activePage: 0,
    totalCount: 0,
    childTotalCount: 0,
    currentParentCategory: 0,
    postsPerPage: 18,
    pagesPerGroup: 3,
    mediaDownloads: [],
    sliced: [],
    paginated: [],
    categories: [],
    childCategories: [],
    filteredCategories: [],
    pages: [],
    slicedPages: [],
    dropdownOpen: false,
    mobileDropdownOpen: false,
    childCategoryDropdown: false,
  }),
  methods: {    
    async getMediaDownloads() {
      let response = await fetch('/graphql',  {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        query: `
          {
            mediaDownloads(
              first: 1000,
              where: {
                orderby: {
                  field: MODIFIED,
                  order: DESC
                }
              }
            ) {
              edges {
                node {
                  id
                  title
                  postTypeMediaDownload {
                    mediaDownload {
                      mediaItemUrl
                      srcSet
                      title
                    }
                    preview {
                      mediaItemUrl
                      srcSet
                    }
                  }
                  mediaDownloadCategories {
                    edges {
                      node {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        `
        })
      });
      response = await response.json()
      this.mediaDownloads = response.data.mediaDownloads
      this.slice(this.mediaDownloads)
      this.totalCount = this.mediaDownloads.edges.length
      this.activeFilterCount = this.mediaDownloads.edges.length
    },
    async getParentCategories() {
      let response = await fetch('/graphql',  {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        query: `
          {
            mediaDownloadCategories(
              first: 100              
            ) {
              edges {
                node {
                  parent {
                    node {
                      id
                    }
                  }
                  name
                  slug
                  termTaxonomyId
                  mediaDownloads(
                    first: 1000,
                    where: {
                      orderby: {
                        field: MODIFIED,
                        order: DESC
                      }
                    }
                  ) {
                    edges {
                      node {
                        id
                        title
                        postTypeMediaDownload {
                          mediaDownload {
                            mediaItemUrl
                            srcSet
                            title
                          }
                          preview {
                            mediaItemUrl
                            srcSet
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `
        })
      });
      response = await response.json()
      this.categories = response.data.mediaDownloadCategories
      this.childCategories = response.data.mediaDownloadCategories
    },
    async getChildCategories() {
      let response = await fetch('/graphql',  {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        query: `
          {
            mediaDownloadCategories(
              first: 100,
              where: {
                termTaxonomId: ${this.currentParentCategory}
              }
            ) {
              edges {
                node {
                  children {
                    edges {
                      node {
                        name
                        slug
                        termTaxonomyId
                        mediaDownloads(
                          first: 1000,
                          where: {
                            orderby: {
                              field: MODIFIED,
                              order: DESC
                            }
                          }
                        ) {
                          edges {
                            node {
                              id
                              title
                              postTypeMediaDownload {
                                mediaDownload {
                                  mediaItemUrl
                                  srcSet
                                  title
                                }
                                preview {
                                  mediaItemUrl
                                  srcSet
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `
        })
      });
      response = await response.json()
      this.childCategories = response.data.mediaDownloadCategories.edges[0].node.children

      const hasMediaDownloads = this.childCategories.edges.some((item) => {
        return item.node.mediaDownloads.edges.length
      })      

      if(hasMediaDownloads) {
        this.childCategoryDropdown = true
      }
    },
    async filteredData(categorySource, slug, name, termTaxonomyId) {
      this.childCategoryDropdown = termTaxonomyId ? false : true
      termTaxonomyId = !termTaxonomyId ? this.currentParentCategory : termTaxonomyId
      this.activeFilter = name
      const categoryArray = new Array;

      categorySource.edges.forEach((category) => {
        const categoryObject = new Object;
        if(category.node.slug === slug) {
          categoryObject['name'] = category.node.name
          categoryObject['slug'] = category.node.slug
          categoryObject['contacts'] = category.node.mediaDownloads
          categoryArray.push(categoryObject)
        }
      })

      this.filteredCategories = categoryArray
      this.slice(this.filteredCategories[0].contacts)
      this.activePage = 0
      this.activeFilterCount = this.filteredCategories[0].contacts.edges.length
      this.childTotalCount = this.filteredCategories[0].contacts.edges.length
      this.currentParentCategory = termTaxonomyId
      this.getChildCategories()
      this.dropdownOpen = false      
    },
    clearFilters() {
      this.activeFilter = 'All'
      this.activeFilterCount = this.mediaDownloads.edges.length
      this.activePage = 0
      this.filteredCategories = []
      this.childTotalCount = this.mediaDownloads.edges.length
      this.slice(this.mediaDownloads)
      this.currentParentCategory = 0
      this.childCategoryDropdown = false
    },
    dropdownToggle() {
      this.dropdownOpen = !this.dropdownOpen
    },
    mobileDropdownToggle() {
      this.mobileDropdownOpen = !this.mobileDropdownOpen
    },
    slice(array) {
      const rowCount = array.edges.length
      const chunkSize = this.postsPerPage
      this.sliced = []
      for (let i = 0; i < rowCount; i += chunkSize) {
        const chunk = array.edges.slice(i , i + chunkSize)
        this.sliced.push(chunk)
      }
      this.paginated = this.sliced[0]
      this.slicePages(this.sliced)
    },
    slicePages(array) {
      const rowCount = array.length
      const rows = []
      array.forEach((item, index) => {
        rows.push(index + 1)
      })
      const chunkSize = this.pagesPerGroup
      this.slicedPages = []
      for (let i = 0; i < rowCount; i += chunkSize) {
        const chunk = rows.slice(i , i + chunkSize)
        this.slicedPages.push(chunk)
      }
      this.pages = this.slicedPages[0]
    },
    paginate(index) {
      this.scrollToElement()
      this.paginated = this.sliced[index]
      this.activePage = index
      this.pages = this.slicedPages[Math.floor(this.activePage / this.pagesPerGroup)]
    },
    paginatePrevious() {
      this.scrollToElement()
      if(this.activePage != 0) {
        this.paginated = this.sliced[this.activePage - 1]
        this.pages = this.slicedPages[Math.floor((this.activePage - 1) / this.pagesPerGroup)]
        this.activePage--
      }
    },
    paginateNext() {
      this.scrollToElement()
      if((this.activePage + 1) != this.sliced.length) {
        this.paginated = this.sliced[this.activePage + 1]
        this.pages = this.slicedPages[Math.floor((this.activePage + 1) / this.pagesPerGroup)]
        this.activePage++
      }
    },
    scrollToElement() {
      this.$refs.target.scrollIntoView({
        behavior: 'smooth'
      });
    }
  },
  mounted() {
    this.getMediaDownloads()
    this.getParentCategories()
  }
})

app.component('contact-item', {
  props: ['post', 'index', 'category'],
  data: () => ({
    open: false,   
  }),
  computed: {
    // computed
  },
  methods: {
    // methods
  },
  template: `
  <div class="[ media-download-item ] [ relative ]">
    <svg v-if="post.node.postTypeMediaDownload.mediaDownload.mediaItemUrl.includes('.mp4')" class="[ image-icon ] [ h-[15px] w-[15px] lg:h-[34px] lg:w-[34px] ][ transition ]" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
      <use xlink:href="#svg-icon-video"/>
    </svg>
    <svg v-else class="[ image-icon ] [ h-[15px] w-[15px] lg:h-[34px] lg:w-[34px] ][ transition ]" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
      <use xlink:href="#svg-icon-image"/>
    </svg>
    <figure class="[ adl-image ] [ flex ] [ items-stretch ]">
      <img class="test" v-if="post.node.postTypeMediaDownload.preview"
        :src="post.node.postTypeMediaDownload.preview.mediaItemUrl"
      />
      <img
        v-else-if="post.node.postTypeMediaDownload.mediaDownload"
        :src="
          (post.node.postTypeMediaDownload.mediaDownload.mediaItemUrl.includes('.mp4') ||
          post.node.postTypeMediaDownload.mediaDownload.mediaItemUrl.includes('.eps')) &&
          post.node.postTypeMediaDownload.preview ?
          post.node.postTypeMediaDownload.preview.mediaItemUrl :
          post.node.postTypeMediaDownload.mediaDownload.mediaItemUrl
        "
        :srcset="
          (post.node.postTypeMediaDownload.mediaDownload.mediaItemUrl.includes('.mp4') ||
          post.node.postTypeMediaDownload.mediaDownload.mediaItemUrl.includes('.eps')) &&
          post.node.postTypeMediaDownload.preview ?
          post.node.postTypeMediaDownload.preview.srcSet :
          post.node.postTypeMediaDownload.mediaDownload.srcSet
        "
        :alt="post.node.postTypeMediaDownload.mediaDownload.title"
      />
    </figure>    
    <!--<div v-if="post.node.postTypeMediaDownload.mediaDownload" class="[ media-download-item-preview ] [ bg-adl-blue-100 ]" :style="{ 'background-image': 'url(' + post.node.postTypeMediaDownload.mediaDownload.mediaItemUrl + ')' }">
    </div>-->
    <a :href="post.node.postTypeMediaDownload.mediaDownload.mediaItemUrl.replace('-scaled.','.')" target="_blank" title="zoom in media" class="[ search-icon ]">
      <svg class="[ h-[15px] w-[15px] lg:h-[40px] lg:w-[40px] ][ transition ]" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
        <use xlink:href="#svg-icon-search"/>
      </svg>
    </a>
    <a v-if="post.node.postTypeMediaDownload.mediaDownload" class="[ media-label ] [ flex items-center gap-1 ]" title="download media" :href="post.node.postTypeMediaDownload.mediaDownload.mediaItemUrl.replace('-scaled.','.')" target="_blank" download>
      <p v-if="post.node.postTypeMediaDownload.mediaDownload.mediaItemUrl.includes('.mp4')">Video</p>
      <p v-else>High-Res Image</p>
      <span>
        <svg class="[ download-icon ] [ h-[15px] w-[15px] lg:h-[17px] lg:w-[12px] ][ transition ]" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
          <use xlink:href="#svg-icon-download"/>
        </svg>
      </span>
    </a>
  </div>
  <h2 class="text-lg">{{ post.node.title }}</h2>
  `
})

app.mount('#media-download-archive')