const initGalleryLightboxes = () => {    
  const galleryLightboxes = document.querySelectorAll('.gallery-lightbox-section');
  if(galleryLightboxes.length) {
    galleryLightboxes.forEach((item) => {

      let currentSlide = 1;
      const modal = item.nextElementSibling;
      const buttons = item.querySelectorAll('.gallery-lightbox-item-btn');
      const close = modal.querySelector('.gallery-lightbox-close');
      const arrows = modal.querySelectorAll('.gallery-lightbox-arrow');
      const slides = modal.querySelectorAll('.gallery-lightbox-slides');

      modal.addEventListener('click', (e) => {
        modal.style.display = "none";
        slides.forEach((slide) => {          
          slide.style.display = "none"          
        })
      })

      close.addEventListener('click', (e) => {
        modal.style.display = "none";
        slides.forEach((slide) => {          
          slide.style.display = "none"          
        })
      })

      buttons.forEach((button) => {
        button.addEventListener('click', (e) => {
          e.preventDefault()
          modal.style.display = "block";          
          currentSlide = button.dataset.currentSlide;
          slides[currentSlide - 1].style.display = "block"
        })
      })

      arrows.forEach((arrow) => {
        arrow.addEventListener('click', () => {
          
          if(arrow.classList.contains('gallery-lightbox-arrow-prev')) {
            console.log(currentSlide)
            console.log(slides.length)
            if(currentSlide > 1) {
              currentSlide -= 1;
            }
          } else {
            console.log(currentSlide)
            console.log(slides.length)
            if(currentSlide < slides.length) {
              currentSlide++;
            }
          }

          slides.forEach((slide) => {            
            if(slide.dataset.slide != currentSlide) {
              slide.style.display = "none"
            }
          })
          slides[currentSlide - 1].style.display = "block"
          

        })
      })
      

    })
  }
}
window.addEventListener('load', () => { initGalleryLightboxes() })