const openSearch = () => {
  const searchOpen = document.querySelector('.search-open')
  const search = document.querySelector('.header-search')
  const searchInput = document.querySelector('.header-search input#s')
  searchOpen.addEventListener('click', (e) => { 
    search.setAttribute('aria-hidden', false)
    setTimeout = () => {      
      searchInput.focus()
    }, 500;
  })
}
window.addEventListener('load', () => { openSearch() })



const closeSearch = () => {  
  const searchClose = document.querySelector('.search-close')
  const search = document.querySelector('.header-search')
  searchClose.addEventListener('click', (e) => { search.setAttribute('aria-hidden', true) })
}
window.addEventListener('load', () => { closeSearch() })


const closeOffClick = (event) => {  
  const searchOpen = document.querySelector('.search-open')
  const search = document.querySelector('.header-search')
  const searchBar = document.querySelector('.header-search-bar')
  if ( !searchBar.contains(event.target) && !searchOpen.contains(event.target) ) {
    search.setAttribute('aria-hidden', true)
  }
}
document.addEventListener('click', (event) => { closeOffClick(event) })