const { createApp } = Vue


const app = createApp({  
  data: () => ({
    activeFilter: 'All',
    departments: [],    
    filteredDepartments: [],
    regions: [],
    dropdownOpen: false
  }),
  methods: {
    async getDepartments() {
      let response = await fetch('/graphql',  {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        query: `
          {
            contactDepartments(
              first: 99,
              where: {
                orderby: COUNT,
                order: DESC
              }
            ) {
              edges {
                node {
                  slug
                  name
                  contacts(first: 99) {
                    edges {
                      node {
                        slug
                        title
                        featuredImage{
                          node {
                            sourceUrl(size: SM_ASPECT_SQUARE)
                            altText
                            title
                          }
                        }
                        contactCustomFields {
                          email
                          phone
                          position
                        }
                        contactRegions {
                          nodes {
                            slug
                          }
                        }
                        contactCountries {
                          nodes {
                            name
                            taxonomyContactCountry {
                              flag {
                                sourceUrl
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `
        })
      });
      response = await response.json();
      this.departments = response.data.contactDepartments;
    },
    async getRegions() {
      let response = await fetch('/graphql',  {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        query: `
          {
            contactRegions {
              edges {
                node {
                  databaseId
                  slug
                  name
                }
              }
            }
          }
        `
        })
      });
      response = await response.json();
      this.regions = response.data.contactRegions;
    },
    async filteredData(slug, name) {

      this.activeFilter = name

      const departmentArray = new Array;

      this.departments.edges.forEach((department) => {
        const departmentObject = new Object;        
        const contactObject = department.node.contacts.edges.filter((contact) => {
          if (contact.node.contactRegions.nodes.some(item => item.slug === slug)) {
            return department;
          }
        })        
        departmentObject['name'] = department.node.name;
        departmentObject['slug'] = department.node.slug;
        departmentObject['contacts'] = contactObject;
        departmentArray.push(departmentObject);
      })
      this.filteredDepartments = departmentArray      

    },
    clearFilters() {
      this.activeFilter = 'All'
      this.filteredDepartments = []
    },
    dropdownToggle() {
      this.dropdownOpen = !this.dropdownOpen
    }
  },
  mounted() {
    // this.getSalesPosts()
    // this.getMediaPosts()
    // this.getSupportPosts()
    this.getDepartments()
    this.getRegions()
  }
})

app.component('icon-phone', {
  template: `
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.61801 0C2.37694 0 2.13947 0.0864503 1.94157 0.244942L1.91279 0.259351L1.89839 0.273759L0.459162 1.75782L0.473555 1.77223C0.0291918 2.18287 -0.107535 2.79703 0.0849622 3.32834C0.0867611 3.33194 0.0831632 3.33914 0.0849622 3.34274C0.475354 4.46119 1.47382 6.62065 3.42398 8.57298C5.38133 10.5325 7.56717 11.4925 8.64839 11.9157H8.66278C9.22228 12.103 9.82856 11.9698 10.2603 11.5987L11.714 10.1435C12.0953 9.76168 12.0953 9.09889 11.714 8.71707L9.84295 6.84398L9.82856 6.81516C9.44716 6.43334 8.77072 6.43334 8.38933 6.81516L7.46822 7.7373C7.1354 7.57701 6.34202 7.16637 5.58283 6.44055C4.82903 5.72013 4.44403 4.89164 4.30191 4.56746L5.22302 3.64532C5.60981 3.25809 5.61701 2.61332 5.20863 2.2333L5.22302 2.21889L5.17984 2.17567L3.33763 0.273759L3.32323 0.259351L3.29445 0.244942C3.09655 0.0864503 2.85908 0 2.61801 0ZM2.61801 0.922136C2.65219 0.922136 2.68637 0.938345 2.71876 0.965361L4.56097 2.85286L4.60415 2.89608C4.60055 2.89248 4.63113 2.94111 4.57536 2.99694L3.42398 4.14961L3.20809 4.35133L3.30884 4.6395C3.30884 4.6395 3.83776 6.05692 4.94956 7.11774L5.05031 7.20419C6.12074 8.18216 7.38187 8.71707 7.38187 8.71707L7.66971 8.84674L9.03698 7.47795C9.11614 7.3987 9.10175 7.3987 9.18091 7.47795L11.0663 9.36545C11.1455 9.44469 11.1455 9.41587 11.0663 9.49512L9.65585 10.9071C9.44356 11.089 9.21869 11.1269 8.95063 11.0368C7.90719 10.6262 5.87967 9.73466 4.07163 7.92461C2.24921 6.10015 1.29751 4.03074 0.948501 3.02576C0.878338 2.83845 0.928711 2.56109 1.09242 2.42061L1.12121 2.39179L2.51726 0.965361C2.54965 0.938345 2.58383 0.922136 2.61801 0.922136Z" fill="black"/>
  </svg>
  `
})

app.component('icon-email', {
  template: `      
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666016 0.333313H13.3327V9.66665H0.666016V0.333313ZM1.73268 3.76863V8.59998H12.266V3.69522L7.00421 6.3983L1.73268 3.76863ZM1.73268 2.57661L6.99713 5.20275L12.266 2.49603V1.39998H1.73268V2.57661Z" fill="black"/>
  </svg>               
  `
})

app.component('contact-item', {
  props: ['post', 'index', 'department'],
  data: () => ({
    open: false,    
  }),
  computed: {
    countryFlagUrl() {
      return this.$props.post.node.contactCountries.nodes[0].taxonomyContactCountry.flag.sourceUrl;
    },
    countryName() {
      return this.$props.post.node.contactCountries.nodes[0].name;
    }
  },
  methods: {
    // Methods go here
  },
  template: `    
    <div class="[ relative ]">

      <div class="accordion-header">
        <button @click="open = ! open" :aria-controls="post.node.slug + '-' + department + '-panel'" aria-expanded="true" class="accordion-trigger [ block ][ py-6 ][ w-full ][ flex justify-between items-start ]" :id="post.node.slug + '-' + department + '-button'">
          <div class="[ flex items-start gap-x-4 ][ w-full ]">

            <ul class="[ list ][ my-0 ]" v-if="post.node.contactCountries.nodes">
              <li v-for="item in post.node.contactCountries.nodes" class="[ w-[24px] mb-2 ]">
                <img v-if="item.taxonomyContactCountry.flag.sourceUrl" :src="item.taxonomyContactCountry.flag.sourceUrl" :alt="countryName + ' flag'" width="24" height="16" />
              </li>
            </ul>            

            <div class="[ text-left ][ mt-[-1px] ][ max-w-[50%] ]">
              <h3 class="[ font-medium leading-none text-lg lg:text-xl my-0 ]" v-if="post.node.title">{{post.node.title}}</h3>
              <span v-if="post.node.contactCustomFields.position" class="[ block mt-2 text-sm ]">{{post.node.contactCustomFields.position}}</span>
            </div>

            <span v-if="post.node.contactCustomFields.phone && !open" class="[ ml-auto mr-4 md:mr-8 xl:mr-16 ][ min-w-[130px] whitespace-nowrap ][ text-right ]">{{post.node.contactCustomFields.phone}}</span>

          </div>
            <svg class="[ h-[15px] w-[15px] lg:h-[22px] lg:w-[22px] ][ transition ]" :class="open ? 'rotate-45' : ''" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="#svg-plus"/>
          </svg>
        </button>
      </div>

      <div :aria-labelledby="post.node.slug + '-' + department + '-button'" class="accordion-panel [ pb-6 ]" :aria-hidden="! open ? 'true' : 'false'" :id="post.node.slug + '-' + department + '-panel'">        
          <div class="[ flex justify-between ][ col-span-4 ]">
            
            <ul class="[ list ]">
              <li v-if="post.node.contactCustomFields.phone" class="[ flex items-center gap-4 ][ mb-2 ]">
                <icon-phone />
                <span>{{post.node.contactCustomFields.phone}}</span>
              </li>
              <li v-if="post.node.contactCustomFields.email" class="[ flex items-center gap-4 ]">
                <icon-email />
                <a :href="'mailto:' + post.node.contactCustomFields.email">{{post.node.contactCustomFields.email}}</a>
              </li>
            </ul>

            <figure v-if="post.node.featuredImage" class="[ absolute top-6 right-8 lg:right-16 ][ w-[96px] lg:w-[120px] ]">
              <img :src="post.node.featuredImage.node.sourceUrl" :alt="post.node.featuredImage.node.altText ? post.node.featuredImage.node.altText : post.node.featuredImage.node.title" width="120" height="120" class="[ rounded-full ]" />
            </figure>

          </div>              
      </div>

    </div>

  `
})

app.mount('#contact-archive')