const animatedEmissionIcon = document.getElementById('epDoWhl04fd1');
if(animatedEmissionIcon) {
	animatedEmissionIcon.parentElement.addEventListener('mouseenter', (e) => {
		e.preventDefault();
		animatedEmissionIcon.svgatorPlayer.play();
	});
}

const animatedLeafIcon = document.getElementById('eVqUkcWldrT1');
if(animatedLeafIcon) {
	animatedLeafIcon.parentElement.addEventListener('mouseenter', (e) => {
		e.preventDefault();
		animatedLeafIcon.svgatorPlayer.play();
	});
}

const animatedLeavesIcon = document.getElementById('eijGCaP7vAL1');
if(animatedLeavesIcon) {
	animatedLeavesIcon.parentElement.addEventListener('mouseenter', (e) => {
		e.preventDefault();
		animatedLeavesIcon.svgatorPlayer.play();
	});
}

const animatedDepartmentIcon = document.getElementById('e5uO8jb3hxA1');
if(animatedDepartmentIcon) {
	animatedDepartmentIcon.parentElement.addEventListener('mouseenter', (e) => {
		e.preventDefault();
		animatedDepartmentIcon.svgatorPlayer.play();
	});
}

const animatedGeneralIcon = document.getElementById('efbGdYQH5me1');
if(animatedGeneralIcon) {
	animatedGeneralIcon.parentElement.addEventListener('mouseenter', (e) => {
		e.preventDefault();
		animatedGeneralIcon.svgatorPlayer.play();
	});
}

const animtedAdlIcon = document.getElementById('epkhgRO9Nzx1');
if(animtedAdlIcon) {
	animtedAdlIcon.parentElement.addEventListener('mouseenter', (e) => {
		e.preventDefault();
		animtedAdlIcon.svgatorPlayer.play();
	});
}

const animtedBuildingsIcon = document.getElementById('eM1WjauyYtC1');
if(animtedBuildingsIcon) {
	animtedBuildingsIcon.parentElement.addEventListener('mouseenter', (e) => {
		e.preventDefault();
		animtedBuildingsIcon.svgatorPlayer.play();
	});
}

const animtedContractingIcon = document.getElementById('ewTLc7dR3XD1');
if(animtedContractingIcon) {
	animtedContractingIcon.parentElement.addEventListener('mouseenter', (e) => {
		e.preventDefault();
		animtedContractingIcon.svgatorPlayer.play();
	});
}

const animtedCoachIcon = document.getElementById('epOnmb9X5h31');
if(animtedCoachIcon) {
	animtedCoachIcon.parentElement.addEventListener('mouseenter', (e) => {
		e.preventDefault();
		animtedCoachIcon.svgatorPlayer.play();
	});
}

const animtedPersonIcon = document.getElementById('eY6da2zIk731');
if(animtedPersonIcon) {
	animtedPersonIcon.parentElement.addEventListener('mouseenter', (e) => {
		e.preventDefault();
		animtedPersonIcon.svgatorPlayer.play();
	});
}