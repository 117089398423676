// Require all your scripts here, either by path or from node_modules name
// They will all be minified into ./main.min.js


import Alpine from 'alpinejs'

// Intersection observer plugin for triggering Alpine when in viewport
// https://alpinejs.dev/plugins/intersect
import intersect from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'

Alpine.plugin(intersect)
Alpine.plugin(collapse)

// Import and add this to Alpine
// import ajax_next from './ajax-archive-next.js'
// Alpine.data('ajax_next', ajax_next);
// import tags from './tags.js'
// Alpine.data('tags', tags);

// import ajax_archive from './ajax_archive.js'
// Alpine.data('ajax_archive', ajax_archive);

// Make Apline available everywhere and start it
window.Alpine = Alpine
Alpine.start()

import './sprites.js' // svg spritesheet
import './header.js'
import './menu.js'
import './search.js'
import './sliders.js'
import './toggles.js'
import './animated-icons.js'
import './gallery-lightbox.js'

import './facility-archive.js'
import './team-archive.js'
import './contact-archive.js'
import './media-download-archive.js'


// Review Cookies
const cookieReview = document.querySelector('.adl-cookie-review a');
if(cookieReview) {
	cookieReview.addEventListener('click', (e) => {
		e.preventDefault();
		const cookieOverlay = document.querySelector('.cky-overlay');
		const cookieModal = document.querySelector('.cky-modal');
		cookieModal.classList.add('cky-modal-open');
		cookieOverlay.classList.remove('cky-hide');
	});
}



const busFilterScroller = document.querySelector('.bus-filters-scroller');
if(busFilterScroller) {
	busFilterScroller.style.height = window.innerHeight - (busFilterScroller.getBoundingClientRect().top + window.scrollY) + "px"
	// console.log(window.innerHeight)
}

// test
document.addEventListener('alpine:init', () => {
	Alpine.data('dropdown', () => ({
		open: false,

		toggle() {
			this.open = ! this.open
		},
	}))
})