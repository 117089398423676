const openMobileMenu = () => {
  const openBtn = document.getElementById('open-menu')
  const siteNav = document.getElementById('site-nav')  
  openBtn.addEventListener('click', (e) => { 
    siteNav.setAttribute('aria-hidden', false) 
    document.documentElement.classList.add('no-scroll')
  })
}
window.addEventListener('load', () => { openMobileMenu() })


const closeMobileMenu = () => {  
  const closeBtns = document.querySelectorAll('.mega-menu-close')
  const siteNav = document.getElementById('site-nav')  
  closeBtns.forEach((closeBtn) => {
    closeBtn.addEventListener('click', (e) => { 
      siteNav.setAttribute('aria-hidden', true) 
      siteNav.querySelectorAll('.mega-menu').forEach((menu) => {
        menu.setAttribute('aria-hidden', true)
      })
      document.documentElement.classList.remove('no-scroll')
    })
  })
}
window.addEventListener('load', () => { closeMobileMenu() })


const megaMenuBack = () => {
  const backBtns = document.querySelectorAll('.mega-menu-back')
  backBtns.forEach((backBtn) => {
    backBtn.addEventListener('click', (e) => { 
      e.currentTarget.closest('.mega-menu').setAttribute('aria-hidden', true)      
    })
  })  

}
window.addEventListener('load', () => { megaMenuBack() })


const isMegaMenuOpen = () => {
  const allMenus = document.querySelectorAll('.mega-menu');
  const megaMenuOpen = Array.from(allMenus).some((item) => {
    return item.getAttribute('aria-hidden') == 'false'
  })  
  if (window.innerWidth >= 1024) {
    if(megaMenuOpen) {
      document.documentElement.classList.add('mega-menu-open');
    } else {
      document.documentElement.classList.remove('mega-menu-open');
    }
  }
}

const toggleMegaMenus = () => {  
  const siteNav = document.getElementById('site-nav')
  const siteNavMegaToggles = siteNav.querySelectorAll('.has-mega-menu > a')
  siteNavMegaToggles.forEach((item) => {          
    item.addEventListener('click', (e) => {      
      e.preventDefault()
      // OPEN THIS MEGA MENU
      item.setAttribute('aria-expanded', item.getAttribute('aria-expanded') === 'true' ? 'false' : 'true')      
      item.nextElementSibling.setAttribute('aria-hidden', item.nextElementSibling.getAttribute('aria-hidden') === 'true' ? 'false' : 'true')      
      // CLOSE OTHER MEGA MENU
      siteNav.querySelectorAll('.has-mega-menu > a').forEach((menuItem) => {
        if(menuItem != item) {
          menuItem.setAttribute('aria-expanded', 'false')
        }
      })
      siteNav.querySelectorAll('.mega-menu').forEach((menu) => {
        if(menu != item.nextElementSibling) {
          menu.setAttribute('aria-hidden', 'true')
        }
      })
      
      isMegaMenuOpen();

    })
  })
}
window.addEventListener('load', () => { toggleMegaMenus() })


const resetMenus = () => {
  const siteNav = document.getElementById('site-nav')
  const megaMenus = siteNav.querySelectorAll('.mega-menu')
  if (window.innerWidth >= 1024) {
    siteNav.setAttribute('aria-hidden', false)
  } else {
    siteNav.setAttribute('aria-hidden', true)
    document.documentElement.classList.remove('mega-menu-open');
  }  
  megaMenus.forEach((menu) => { menu.setAttribute('aria-hidden', 'true') })
}
window.addEventListener('load', () => { resetMenus() })
window.addEventListener('resize', () => { resetMenus() })


const closeOffClick = (event) => {
  if (window.innerWidth >= 1024) {
    const siteNav = document.getElementById('site-nav')  
    const activeToggle = siteNav.querySelector('.menu-item.has-mega-menu > a[aria-expanded="true"]')
    const activeMenu = siteNav.querySelector('.mega-menu[aria-hidden="false"] .mega-menu-inner')
    if(activeToggle || activeMenu) {
      if ( !activeMenu.contains(event.target) && !activeToggle.contains(event.target) ) {
        siteNav.querySelectorAll('.has-mega-menu > a').forEach((menuItem) => {      
          menuItem.setAttribute('aria-expanded', 'false')
        })
        siteNav.querySelectorAll('.mega-menu').forEach((menu) => {      
          menu.setAttribute('aria-hidden', 'true')      
        })
      }
      isMegaMenuOpen()
    }
  }
}
document.addEventListener('click', (event) => { closeOffClick(event) })